import { TableProps } from "antd";
import cn from "classnames/dedupe";

import { COLOR_BY_VACANCY_STATUS } from "common/const/colors";
import {
  CANDIDATE_STATUS_TYPE,
  VACANCY_STATUS_TYPE,
} from "common/const/status";
import { Candidate, Manager, Report } from "common/commonTypes/reports";
import { getDateFormat } from "common/utils/date";
import { VacancyStatus } from "graphql/types/types";

export const columnsCallCenter: TableProps<Report>["columns"] = [
  {
    title: "Департамент",
    dataIndex: "department",
    key: "department",
    render: (department) => {
      const departmentName = department?.name ?? "";
      return (
        <div className="nowrap" title={departmentName}>
          {departmentName}
        </div>
      );
    },
  },
  {
    title: "ФИО менеджера",
    dataIndex: "manager",
    key: "manager",
    render: (manager: Manager) => {
      const { firstName = "", secondName = "" } = manager ?? {};
      const name = `${firstName} ${secondName}`;
      return (
        <div className="report-table-column-name" title={name}>
          {name}
        </div>
      );
    },
  },
  {
    title: "ID вакансии",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Вакансия",
    dataIndex: "name",
    key: "name",
    render: (name) => (
      <div className="nowrap" title={name}>
        {name}
      </div>
    ),
  },
  {
    title: "Проект",
    dataIndex: "callCenterInfo",
    key: "callCenterInfo",
    render: (callCenterInfo) => {
      const { project = "" } = callCenterInfo || {};
      return (
        <div
          className={cn("nowrap", "report-table-column-info")}
          title={project}
        >
          {project}
        </div>
      );
    },
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
    render: (address) => (
      <div
        className={cn("nowrap", "report-table-column-address")}
        title={address?.value}
      >
        {address?.value ?? ""}
      </div>
    ),
  },
  {
    title: "Потребность",
    dataIndex: "peopleCount",
    key: "peopleCount",
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (status: VacancyStatus) => (
      <div
        className="nowrap"
        title={VACANCY_STATUS_TYPE[status]}
        style={{ color: COLOR_BY_VACANCY_STATUS[status] }}
      >
        {VACANCY_STATUS_TYPE[status]}
      </div>
    ),
  },
  {
    title: "Дата и время взятия в работу",
    dataIndex: "dateStartWork",
    key: "dateStartWork",
    render: (dateStartWork) =>
      dateStartWork ? getDateFormat(dateStartWork, "DD.MM.YY HH:mm") : null,
  },
  {
    title: "Дата и время окончания работы с вакансией",
    dataIndex: "dateEndWork",
    key: "dateEndWork",
    render: (dateEndWork) =>
      dateEndWork ? getDateFormat(dateEndWork, "DD.MM.YY HH:mm") : null,
  },
  {
    title: "Дата и время открытия вакансии без обработки",
    dataIndex: "managerStartWork",
    key: "managerStartWork",
    render: (managerStartWork) =>
      managerStartWork
        ? getDateFormat(managerStartWork, "DD.MM.YY HH:mm")
        : null,
  },
  {
    title: "Длительность вакансии в работе",
    dataIndex: "dateRangeWork",
    key: "dateRangeWork",
    render: (dateRangeWork) => (dateRangeWork ? `${dateRangeWork} ч.` : null),
  },
  {
    title: "Подобрано кандидатов системой",
    dataIndex: "cntCandidatesFind",
    key: "cntCandidatesFind",
  },
  {
    title: "Обзвон кандидатов количество",
    dataIndex: "cntCandidatesCalls",
    key: "cntCandidatesCalls",
  },
  {
    title: "Направлено кандидатов",
    dataIndex: "cntCandidatesDirected",
    key: "cntCandidatesDirected",
  },
  {
    title: "ФИО направленных кандидатов",
    dataIndex: "fioCandidatesDirected",
    key: "fioCandidatesDirected",
  },
  {
    title: "Размещена Rabotut.ru",
    dataIndex: "extraRbt",
    key: "extraRbt",
    render: (extraRbt) =>
      extraRbt.remoteUrl ? (
        <a target="_blank" rel="noopener noreferrer" href={extraRbt.remoteUrl}>
          Ссылка
        </a>
      ) : null,
  },
  {
    title: "Размещена Авито",
    dataIndex: "extraAvito",
    key: "extraAvito",
    render: (extraAvito) =>
      extraAvito.remoteUrl ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={extraAvito.remoteUrl}
        >
          Ссылка
        </a>
      ) : null,
  },
  {
    title: "Размещена HH.ru",
    dataIndex: "extraHH",
    key: "extraHH",
    render: (extraHH) =>
      extraHH.remoteUrl ? (
        <a target="_blank" rel="noopener noreferrer" href={extraHH.remoteUrl}>
          Ссылка
        </a>
      ) : null,
  },
];

export const columnsPilot: TableProps<Report>["columns"] = [
  {
    title: "ID вакансии",
    dataIndex: "vacancyId",
    key: "vacancyId",
  },
  {
    title: "Название вакансии",
    dataIndex: "positionName",
    key: "positionName",
    render: (positionName) => (
      <div className="nowrap" title={positionName}>
        {positionName}
      </div>
    ),
  },
  {
    title: "Потребность",
    dataIndex: "candidateCount",
    key: "candidateCount",
  },
  {
    title: "Проект",
    dataIndex: "project",
    key: "project",
  },
  {
    title: "Департамент",
    dataIndex: "departamentName",
    key: "departamentName",
  },
  {
    title: "ID менеджера КЦ",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row  report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ managerId }) => (
        <div className="report-table-cell">{managerId}</div>
      )),
  },
  {
    title: "ФИО менеджера КЦ",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row",
    render: (candidates: Candidate[]) =>
      candidates.map(({ managerFullName }) => (
        <div className="report-table-cell nowrap">{managerFullName}</div>
      )),
  },
  {
    title: "ID кандидата",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row",
    render: (candidates: Candidate[]) =>
      candidates.map(({ candidateId }) => (
        <div className="report-table-cell nowrap">{candidateId}</div>
      )),
  },
  {
    title: "ФИО кандидата",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row",
    render: (candidates: Candidate[]) =>
      candidates.map(({ candidateFullName }) => (
        <div className="report-table-cell nowrap">{candidateFullName}</div>
      )),
  },
  {
    title: "Текущий статус кандидата",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ candidateStatus }) => (
        <div className="report-table-cell nowrap">
          {CANDIDATE_STATUS_TYPE[candidateStatus]}
        </div>
      )),
  },
  {
    title: "Дата взятия вакансии в работу ",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ vacancyTakenDate }) => {
        return (
          <div className="report-table-cell nowrap">
            {vacancyTakenDate
              ? getDateFormat(vacancyTakenDate, "DD.MM.YY HH:mm")
              : null}
          </div>
        );
      }),
  },
  {
    title: "Дата установки статуса «Кандидат»",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ candidateStatusDate }) => {
        return (
          <div className="report-table-cell nowrap">
            {candidateStatusDate
              ? getDateFormat(candidateStatusDate, "DD.MM.YY HH:mm")
              : null}
          </div>
        );
      }),
  },
  {
    title: "Дата установки статуса «В архиве»",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ archivedStatusDate }) => {
        return (
          <div className="report-table-cell nowrap">
            {archivedStatusDate
              ? getDateFormat(archivedStatusDate, "DD.MM.YY HH:mm")
              : null}
          </div>
        );
      }),
  },
];
