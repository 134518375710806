import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Spinner } from "components/UI/Spinner";
import {
  selectCandidateData,
  selectCandidateList,
} from "services/store/modules/candidates/selectors";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { Title } from "components/UI/Title";
import { VacancyInfo } from "components/VacancyInfo";
import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { ROUTE } from "routes";
import { PAGINATION } from "views/redezign/VacancyPage";
import { CandidatesTable } from "components/CandidatesTable";
import { useToggle } from "common/hooks/useToggle";
import { PublishModal } from "./components/PublishModal";
import { Platform } from "common/const/platform";
import { setSessionIds } from "common/utils/cookies";
import { VacancyType } from "graphql/types/types";
import { HOUR } from "common/const/time";
import { useTimeout } from "common/hooks/useTimeout";
import { useChangeVisible } from "common/hooks/visible/useChangeVisible";
import { ModalConfirm } from "components/ModalConfirm";
import { setVacancy } from "services/store/modules/vacancy";
import { selectVacancyLoading } from "services/store/modules/vacancy/selectors";
import { userList } from "services/store/modules/user/selectors";
import { authInfo } from "services/store/modules/auth";
import { getUrlParams } from "common/utils/routes";
import { selectCallsError } from "services/store/modules/calls";

import "./styles.scss";

interface Props {
  vacancyId: string;
  vacancy: VacancyType;
  getCandidateList: (pagination?: PaginationPayload) => void;
}

const MAX_TIME_VACANCY_BLOCK = 8 * HOUR;

const CONFIRM_MESSAGE =
  "При выходе из вакансии она перестанет быть закреплена за вами";

export const Vacancy: React.FC<Props> = ({
  vacancyId,
  vacancy,
  getCandidateList,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { onChangeVacancyVisible } = useChangeVisible({ id: vacancy.id });

  const { data, loading: candidateLoading } =
    useAppSelector(selectCandidateList);
  const vacancyLoading = useAppSelector(selectVacancyLoading);
  const managerList = useAppSelector(userList);
  const { data: candidatesList = [], meta } = data || {};
  const userData = useAppSelector(authInfo);
  const candidate = useAppSelector(selectCandidateData);

  const { fromRecommended } = getUrlParams();
  const callsError = useAppSelector(selectCallsError);

  const [openPublishModal, togglePublishModal] = useToggle(false);
  const [publishItems, setPublishItems] = useState<Platform[]>(["rabotut"]);

  const handleNotVisible = async () => {
    const result = await onChangeVacancyVisible({ inWork: false }).unwrap();

    if (result?.changeVisibleVacancy) {
      dispatch(
        setVacancy({
          vacancy: { ...result.changeVisibleVacancy, peopleCountOpen: null },
        })
      );
    }
  };
  useTimeout(handleNotVisible, MAX_TIME_VACANCY_BLOCK);

  const handleRowClick = (candidateId: string) => {
    setSessionIds("openedVacancyCandidateIds", candidateId);
    history.push({
      pathname: `${ROUTE.CANDIDATE}/${candidateId}`,
      search: `?fromVacancy=${vacancyId}`,
    });
  };

  useEffect(() => {
    getCandidateList({
      offset:
        vacancyId === String(vacancy.id)
          ? meta?.offset ?? PAGINATION.offset
          : PAGINATION.offset,
      limit: PAGINATION.limit,
    });
  }, [getCandidateList, vacancy.id, vacancyId]);

  return (
    <div className="vacancy-container">
      {userData && (
        <VacancyInfo
          vacancy={vacancy}
          className="vacancy-container-info"
          publishItems={publishItems}
          managerList={managerList}
          mapLoading={vacancyLoading}
          changeItems={(val) => setPublishItems(val)}
          openModal={togglePublishModal}
          userData={userData}
          isHidden={Boolean(fromRecommended)}
        />
      )}

      <Title type="h3">Анкеты кандидатов</Title>
      <>
        {candidateLoading ? (
          <Spinner
            className="vacancy-container-table-spinner"
            loading={candidateLoading}
          />
        ) : (
          <>
            <CandidatesTable
              className="vacancy-container-table"
              candidatesList={fromRecommended ? [candidate] : candidatesList}
              vacancyId={Number(vacancyId)}
              handleRowClick={handleRowClick}
              callsError={callsError}
              emptyText={
                Math.floor((meta?.total ?? 0) / (meta?.limit ?? 20)) <= 1
                  ? "На данный момент другие кандидаты отсутствуют"
                  : "Перейдите на следующую страницу списка кандидатов"
              }
            />

            {!fromRecommended && (
              <Pagination
                total={meta?.total}
                limit={meta?.limit}
                offset={meta?.offset}
                onChange={(pagination) => {
                  getCandidateList(pagination);
                }}
              />
            )}
          </>
        )}
      </>

      {userData && (
        <PublishModal
          open={openPublishModal}
          vacancy={vacancy}
          publishItems={publishItems}
          onChangeOpen={togglePublishModal}
          userData={userData}
        />
      )}

      <ModalConfirm
        message={CONFIRM_MESSAGE}
        blackRoute={`${ROUTE.CANDIDATE}/:id`}
        isFactor={Boolean(vacancy.peopleCountOpen)}
        onNavigate={handleNotVisible}
      />
    </div>
  );
};
