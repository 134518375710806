import React, { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { ReportTable } from "./components/ReportTable";
import { Filters } from "components/UI/Filters";
import { useToggle } from "common/hooks/useToggle";
import { FiltersModal } from "./components/FiltersModal";
import { getReport } from "services/store/modules/reports/actions";
import {
  selectReport,
  selectReportList,
  selectReportUrl,
} from "services/store/modules/reports/selectors";
import { resetReportList } from "services/store/modules/reports";
import { preparePayload, stateByReportName } from "./helpers";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import { PaginationPayload } from "components/UI/Pagination";
import { ReportName } from "common/const/reports";
import { PAGINATION } from "common/const/pagination";
import { SendReportModal } from "./components/SendReportModal";
import { selectUsersByRoles } from "services/store/modules/user/selectors";
import { UserRole } from "graphql/types/types";
import { FiltersState } from "./types";
import { FilterActions } from "./components/FilterActions";

import "./styles.scss";

type Props = {
  reportName: ReportName;
};

export const Report: React.FC<Props> = ({ reportName }) => {
  const dispatch = useAppDispatch();

  const { loading, meta = null, error } = useAppSelector(selectReport) || {};
  const reportList = useAppSelector(selectReportList);
  const reportUrl = useAppSelector(selectReportUrl);
  const managerList = useAppSelector((state) =>
    selectUsersByRoles(state, [UserRole.Manager])
  );

  const [open, toggleOpen] = useToggle(false);
  const [openSend, toggleOpenSend] = useToggle(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, dirtyFields, errors },
    setError,
    clearErrors,
    getValues,
  } = useForm<FiltersState>({
    defaultValues: stateByReportName[reportName],
  });

  const getReportTable = useCallback(
    (form: FiltersState, pagination?: PaginationPayload) => {
      dispatch(
        getReport(
          preparePayload(form, pagination ?? PAGINATION.REPORT, reportName)
        )
      );
    },
    [dispatch, reportName]
  );

  const updateForm: SubmitHandler<FiltersState> = useCallback(
    async (formData) => getReportTable(formData),
    [getReportTable]
  );

  return (
    <div className="report">
      <div className="report-header">
        <Filters
          className="report-filters"
          count={Object.values(dirtyFields).length}
          onOpen={toggleOpen}
          onReset={() => {
            reset();
            dispatch(resetReportList());
          }}
        />

        {reportList?.length ? (
          <FilterActions
            reportUrl={reportUrl}
            toggleOpenSend={toggleOpenSend}
          />
        ) : null}
      </div>

      {reportList?.length && !loading ? (
        <ReportTable
          formData={getValues()}
          reportName={reportName}
          reportList={reportList}
          meta={meta}
          onChangePagination={getReportTable}
        />
      ) : (
        <NotificationStatus
          loading={loading}
          error={error}
          isCondition={isDirty}
          notFoundText="Отчет не найден"
        />
      )}

      <FiltersModal
        control={control}
        isDirty={isDirty}
        open={open}
        errors={errors}
        reportName={reportName}
        managerList={managerList}
        onCancel={toggleOpen}
        onReset={() => reset(stateByReportName[reportName])}
        setError={setError}
        clearErrors={clearErrors}
        omSubmit={handleSubmit(updateForm)}
      />

      <SendReportModal
        reportName={reportName}
        managerList={managerList}
        open={openSend}
        reportUrl={reportUrl}
        onCancel={toggleOpenSend}
      />
    </div>
  );
};
