import React, { useRef } from "react";

import { useOnClickOutside } from "common/hooks/useOnClickOutside";
import { CandidateType, ContactType } from "graphql/types/types";
import { OktellButton } from "./components/OktellButton";
import { Dropdown, Props } from "components/UI/Dropdown";

import "./styles.scss";
import { MangoButton } from "./components/MangoButton";

export interface DropdownMenuProps {
  isVisible: boolean;
  candidate: CandidateType;
  vacancyId: number;
  contact: ContactType["contact"];
  setDropdownVisible: (visible: boolean) => void;
}

export const DropdownCallsMenu: React.FC<DropdownMenuProps> = ({
  isVisible,
  contact,
  candidate,
  vacancyId,
  setDropdownVisible,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setDropdownVisible(false));

  const items: Props["items"] = [
    {
      key: "1",
      label: (
        <div className="dropdown-item" ref={dropdownRef}>
          Октелл{" "}
          <OktellButton
            contact={contact}
            candidate={candidate}
            vacancyId={vacancyId}
          />
        </div>
      ),
    },
    //TODO: убрать когда будет готова интеграция с манго на беке
    // {
    //   key: "2",
    //   label: (
    //     <div className="dropdown-item" ref={dropdownRef}>
    //       Mango <MangoButton candidate={candidate} />
    //     </div>
    //   ),
    // },
  ];

  return (
    <Dropdown
      className="dropdown-calls-menu"
      overlayClassName="dropdown-calls-menu-overlay"
      items={items}
      trigger={["click"]}
      placement="bottomLeft"
      open={isVisible}
    >
      <span />
    </Dropdown>
  );
};
