import React, { useState, useCallback, useRef } from "react";
import { useAppDispatch } from "services/store/store";
import { getDateFormat } from "common/utils/date";
import { useToggle } from "common/hooks/useToggle";
import { useHover } from "common/hooks/useHover";
import { updateCandidateInList } from "services/store/modules/candidates";
import { changeCandidateStatus } from "services/store/modules/candidates/action";
import { CandidateCommentType, CandidateType } from "graphql/types/types";
import { Tooltip } from "components/UI/Tooltip";
import { MessageSquare } from "components/UI/icons";
import { PlusCircle } from "components/UI/icons/PlusCircle";
import { Modal } from "components/UI/Modal";
import FormTextArea from "components/UI/Form/FormTextArea";
import { Button } from "components/UI/Button";
import toaster from "components/UI/Notifications/Notification";
import { updateCandidateInReply } from "services/store/modules/repliesList";

import "./styles.scss";

function renderComments(comments: CandidateCommentType[]) {
  return (
    <div>
      {comments.map((commentObj, index) => (
        <div key={index}>
          {getDateFormat(commentObj.createdAt, "DD.MM.YY")}:{" "}
          {commentObj.comment}
        </div>
      ))}
    </div>
  );
}

interface CommentColumnProps {
  comments: CandidateCommentType[] | null;
  candidate: CandidateType;
  canAddComment: boolean;
  disable?: boolean;
}

export const CommentColumn: React.FC<CommentColumnProps> = ({
  comments,
  candidate,
  canAddComment,
  disable,
}) => {
  const dispatch = useAppDispatch();

  const [isEdit, toggleIsEdit] = useToggle(false);
  const secondNameRef = useRef<HTMLDivElement>(null);

  const isHover = useHover(secondNameRef);

  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { id, status } = candidate;
  const updateCandidateForm = useCallback(
    async (comment: string) => {
      if (!status) return;
      setIsLoading(true);
      try {
        const result = await dispatch(
          changeCandidateStatus({
            id,
            status,
            comment,
            vacancyId: null,
          })
        ).unwrap();

        if (!result?.changeCandidateStatus) {
          toaster.error({ title: "Комментарий не добавлен добавлен" });
          return;
        }

        dispatch(updateCandidateInList(result.changeCandidateStatus));
        dispatch(updateCandidateInReply(result.changeCandidateStatus));
        toaster.success({ title: "Комментарий был успешно добавлен." });
      } catch (error) {
        toaster.error(
          { title: `Ошибка: ${error.message}` },
          { autoClose: 3000 }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [id, status, dispatch]
  );
  const submitComment = async () => {
    await updateCandidateForm(value);
    toggleIsEdit();
    setValue("");
  };
  return (
    <div
      className="comment-container"
      ref={disable ? undefined : secondNameRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {comments?.length ? (
        <div>
          <Tooltip placement="bottomRight" title={renderComments(comments)}>
            <div>
              <MessageSquare />
            </div>
          </Tooltip>
          <div>{comments.length}</div>
        </div>
      ) : null}
      {canAddComment && isHover && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            toggleIsEdit();
          }}
        >
          <PlusCircle />
        </span>
      )}
      <Modal
        title="Оставить комментарий"
        visible={isEdit}
        onCancel={(e) => {
          e.stopPropagation();
          toggleIsEdit();
        }}
        footer={null}
      >
        <div>
          <FormTextArea
            label="Комментарий"
            size="middle"
            style={{ height: "105px", width: "90%" }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button loading={isLoading} onClick={submitComment}>
            Отправить
          </Button>
        </div>
      </Modal>
    </div>
  );
};
