import React from "react";
import { useHistory } from "react-router";

import { DashboardHeader } from "components";
import { ROLE } from "common/const/role";
import { selectCandidate } from "services/store/modules/candidates/selectors";
import { Title } from "components/UI/Title";
import { useAppSelector } from "services/store/store";
import { ROUTE } from "routes";
import { Vector } from "components/UI/icons/Vector";
import { Logout } from "components/UI/icons";
import { logout } from "common/helpers/auth";
import {
  getIsSpecificPage,
  getPathnameWithoutId,
} from "common/helpers/location";
import { selectNavigationHeader } from "services/store/modules/app/selectors";
import { IUser } from "services/store/modules/user/types";
import { selectVacancyData } from "services/store/modules/vacancy/selectors";
import { Button } from "components/UI/Button";

import "./styles.scss";

type Props = {
  authData?: IUser;
  setVisible: () => void;
};

export const Header: React.FC<Props> = ({ authData, setVisible }) => {
  const history = useHistory();

  const { candidate } = useAppSelector(selectCandidate);
  const vacancy = useAppSelector(selectVacancyData);
  const { title } = useAppSelector(selectNavigationHeader);

  const pathname = getPathnameWithoutId();

  const goBack = () => history.goBack();

  const titleByPathname = {
    [ROUTE.CANDIDATE]: `${candidate.secondName ?? ""} ${
      candidate.firstName ?? ""
    } ${candidate.patronymic ?? ""}`,
    [ROUTE.VACANCY]: vacancy?.name,
    [ROUTE.RECOMMENDED]: "Другие вакансии",
  };

  return (
    <DashboardHeader layout="main" className="header">
      <div className="wrapper-title">
        <Button
          onClick={goBack}
          className="back-btn"
          style={{ visibility: getIsSpecificPage() ? "hidden" : "visible" }}
          icon={<Vector height={15} width={15} color={"#A6ACB9"} />}
        >
          Назад
        </Button>
        <Title className="title" type="h1">
          {titleByPathname[pathname] ?? title}
        </Title>
      </div>

      {authData && (
        <div className="user">
          <span className={"caption"}>
            {ROLE[authData.role] ?? "Роль не указана"}
          </span>
          <div className={"user-dropdown"} onClick={setVisible}>
            <span>
              {`${authData.firstName || ""} ${authData.secondName || ""}`}
            </span>
          </div>
          <div title="Выйти из системы" onClick={logout}>
            <Logout color="#0057AC" />
          </div>
        </div>
      )}
    </DashboardHeader>
  );
};
