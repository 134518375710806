import {
  PayloadAction,
  createSlice,
  isAnyOf,
  combineReducers,
} from "@reduxjs/toolkit";
import { CandidateListData } from "./types";
import { ICandidatesDictionary } from "types/ICandidatesDictionary";
import { CandidateType, ReplyListSuccess } from "graphql/types/types";
import { ItemState } from "services/store/types";

import {
  changeCandidateStatus,
  fetchCandidateById,
  fetchCandidateList,
  fetchCandidateListByVacancy,
  fetchCandidatesDictionaries,
  fetchCandidateSuitableList,
  fetchReplyListByCandidate,
  getCandidateHistory,
  setupCallCandidate,
  transferCandidate,
  updateCandidate,
} from "./action";
import { CandidateHistoryResponse } from "api/history";
import { getInitialState } from "../../helpers";

type CandidatesListState = ItemState<CandidateListData | null>;

const initialState: CandidatesListState = {
  data: null,
  loading: true,
  error: null,
};

const candidatesListSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    updateCandidateInList: (
      state,
      { payload }: PayloadAction<CandidateType>
    ) => {
      if (state.data) {
        state.data.data = state.data.data.map((candidate) => {
          return candidate.id === payload.id ? payload : candidate;
        });
      }
    },
    removeCandidate: (
      state,
      { payload }: PayloadAction<{ candidateId: CandidateType["id"] }>
    ) => {
      if (state.data) {
        state.data.data = state.data.data.filter(
          (candidate) => candidate.id !== payload.candidateId
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateListByVacancy.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.candidateListByVacancy || null;
      })
      .addCase(fetchCandidateList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.candidateList || null;
      })
      .addCase(fetchCandidateSuitableList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.candidateSuitableList || null;
      })
      .addMatcher(
        isAnyOf(
          fetchCandidateList.rejected,
          fetchCandidateListByVacancy.rejected,
          fetchCandidateSuitableList.rejected
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message as string;
        }
      )
      .addMatcher(
        isAnyOf(
          fetchCandidateList.pending,
          fetchCandidateListByVacancy.pending,
          fetchCandidateSuitableList.pending
        ),
        (state) => {
          state.loading = true;
        }
      );
  },
});

const repliesSlice = createSlice({
  name: "replies",
  initialState: {
    data: {} as ReplyListSuccess,
    loading: true,
    error: null as string | null,
  },
  reducers: {
    clearRepliesData: (state) => {
      state.data = {} as ReplyListSuccess;
      state.loading = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReplyListByCandidate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReplyListByCandidate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchReplyListByCandidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

//TODO: когда будем удалять старый дизайн переделать добавить getInitialState()
const candidateSlice = createSlice({
  name: "candidate",
  initialState: {
    candidate: {} as CandidateType,
    loading: false,
    error: null as string | null,
  },
  reducers: {
    clearCandidateData: (state) => {
      state.candidate = {} as CandidateType;
      state.loading = true;
      state.error = null;
    },
    setCandidate: (state, { payload }: PayloadAction<CandidateType>) => {
      state.candidate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateById.fulfilled, (state, action) => {
        state.loading = false;
        state.candidate = action.payload;
      })
      .addCase(transferCandidate.fulfilled, (state, action) => {
        state.loading = false;
        state.candidate = action.payload;
      })
      .addCase(setupCallCandidate.fulfilled, (state, action) => {
        state.loading = false;
        state.candidate = action.payload;
      })
      .addCase(updateCandidate.fulfilled, (state, action) => {
        state.loading = false;
        state.candidate = action.payload?.updateCandidate as CandidateType;
      })
      .addCase(changeCandidateStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.candidate = action.payload
          ?.changeCandidateStatus as CandidateType;
      })
      .addMatcher(
        isAnyOf(
          fetchCandidateById.pending,
          transferCandidate.pending,
          setupCallCandidate.pending
        ),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          fetchCandidateById.rejected,
          transferCandidate.rejected,
          setupCallCandidate.rejected
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

const candidatesDictionariesSlice = createSlice({
  name: "candidatesDictionaries",
  initialState: {
    data: null as ICandidatesDictionary | null,
    loading: true,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidatesDictionaries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCandidatesDictionaries.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCandidatesDictionaries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});
const candidateHistorySlice = createSlice({
  name: "candidateHistory",
  initialState: getInitialState<CandidateHistoryResponse>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCandidateHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCandidateHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCandidateHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearRepliesData } = repliesSlice.actions;
export const { clearCandidateData, setCandidate } = candidateSlice.actions;
export const { updateCandidateInList, removeCandidate } =
  candidatesListSlice.actions;

export const candidatesReducer = combineReducers({
  candidatesList: candidatesListSlice.reducer,
  candidatesReply: repliesSlice.reducer,
  candidate: candidateSlice.reducer,
  candidatesDictionaries: candidatesDictionariesSlice.reducer,
  candidateHistory: candidateHistorySlice.reducer,
});
