import React from "react";
import { Controller, Control } from "react-hook-form";

import { Title } from "components/UI/Title";
import RowLayout from "layouts/RowLayout";
import { FormSelect } from "components/UI/Form/FormSelect";
import FormTextArea from "components/UI/Form/FormTextArea";
import FormRadio from "components/UI/Form/FormRadio";
import { OPTION_BLACK_LIST } from "../constants/checkboxOptions";
import { useAppSelector } from "services/store/store";
import { selectSources } from "services/store/modules/candidates/selectors";
import { FiltersFormState } from "../types";

import "./styles.scss";

interface CandidateDetailsProps {
  control: Control<FiltersFormState>;
  disabledForm: boolean;
}

const CandidateDetails: React.FC<CandidateDetailsProps> = ({
  control,
  disabledForm,
}) => {
  const candidateSources = useAppSelector(selectSources);

  return (
    <div className="form-candidate-details">
      <Title type={"h2"} color="blue">
        Дополнительно
      </Title>
      <RowLayout
        leftSpan={15}
        leftComponent={
          <Controller
            name="managerComment"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormTextArea
                label={"Комментарий"}
                style={{ width: "100%", height: "120px" }}
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
        rightComponent={
          <Controller
            name="source"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormSelect
                label={"Источник"}
                options={candidateSources?.map((el) => ({
                  label: el.name,
                  value: el.key,
                }))}
                disabled={true}
                {...field}
              />
            )}
          />
        }
      />
      <RowLayout
        leftComponent={
          <Controller
            name="inBlacklist"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormRadio
                label={"Черный список"}
                options={OPTION_BLACK_LIST}
                style={{ width: "100%" }}
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
      />
    </div>
  );
};

export default CandidateDetails;
