import { gql } from "@apollo/client";

export const CHANGE_CANDIDATE_STATUS = gql`
  mutation changeCandidateStatus(
    $id: String!
    $status: CandidateStatus!
    $comment: String!
    $vacancyId: Int
  ) {
    changeCandidateStatus(
      id: $id
      status: $status
      comment: $comment
      vacancyId: $vacancyId
    ) {
      ... on CandidateType {
        id
        email
        actualAddress {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        age
        badge
        calcDismissDate
        callCompleted
        cardDeliveryAddress {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        citizenshipId
        city
        comments {
          comment
          createdAt
          manager {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
        }
        companyId
        contacts {
          contact
          id
          isMain
        }
        createdAt
        dateOfBirth
        deletedAt
        departmentId
        dismissDate
        dismissReason
        driverLicense
        experienceFull
        experienceInCompany
        firstName
        fullName
        hasChat
        hasHistory
        haveCar
        inBlacklist
        isSpam
        lastShiftRate
        location {
          lat
          lng
        }
        managerComment
        managerId
        metro {
          distance
          line
          name
        }
        ownerId
        patronymic
        positionId
        positions {
          key
        }
        receptionLastDate
        region
        russianCitizenship
        salaryExpectation
        secondName
        sex
        shiftMaxDate
        shiftMinDate
        source
        status
        transitDuration
        vacancyCount
        updatedAt
        updatedBy
        updater {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        walkingDuration
      }
      ... on Error {
        message
        errors
        statusCode
      }
    }
  }
`;
