import React from "react";
import { Controller, Control } from "react-hook-form";

import { Title } from "components/UI/Title";
import RowLayout from "layouts/RowLayout";
import { FormInput } from "components/UI/Form/FormInput";
import FormDatePicker from "components/UI/Form/FormDatePicker";
import { FormSelect } from "components/UI/Form/FormSelect";
import { OPTION_TERMINATION_REASON } from "../constants/selectOptions";
import FormText from "components/UI/Form/FormText";
import { getDateFormat } from "common/utils/date";
import { formatDurationMonths } from "common/helpers/formatDurationMonths";
import { positions } from "services/store/modules/directory";
import { filterOption } from "components/UI/Select/helpers";
import { useAppSelector } from "services/store/store";
import { selectCandidate } from "services/store/modules/candidates/selectors";
import { Tooltip } from "components/UI/Tooltip";
import { FiltersFormState } from "../types";

import "./styles.scss";

interface CandidatePositionProps {
  control: Control<FiltersFormState>;
  disabledForm: boolean;
}

const CandidatePosition: React.FC<CandidatePositionProps> = ({
  control,
  disabledForm,
}) => {
  const positionsDirectory = useAppSelector(positions);
  const { candidate } = useAppSelector(selectCandidate);
  return (
    <div className="form-candidate-details">
      <Title type={"h2"} color="blue">
        Должность и зарплата
      </Title>
      <RowLayout
        leftComponent={
          <Controller
            name="positionId"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormSelect
                label={"Должность"}
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                disabled={disabledForm}
                options={positionsDirectory?.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                {...field}
              />
            )}
          />
        }
        centerComponent={<></>}
        rightComponent={
          <FormText
            label={"Дата приема"}
            text={
              candidate.receptionLastDate
                ? getDateFormat(candidate.receptionLastDate, "D MMMM YYYY")
                : "Не указана"
            }
            style={{ fontSize: "14px" }}
          />
        }
      />
      <RowLayout
        leftComponent={
          <Controller
            name="shiftMinDate"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormDatePicker
                label={"Дата первой смены"}
                style={{ width: "100%" }}
                format={"DD.MM.YYYY"}
                defaultValue={field.value}
                disabled={true}
                {...field}
              />
            )}
          />
        }
        centerComponent={
          <Controller
            name="shiftMaxDate"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormDatePicker
                label={"Дата последней смены"}
                style={{ width: "100%" }}
                format={"DD.MM.YYYY"}
                defaultValue={field.value}
                disabled={true}
                {...field}
              />
            )}
          />
        }
        rightComponent={
          <FormText
            label={"Дата увольнения"}
            text={
              candidate.dismissDate
                ? getDateFormat(candidate.dismissDate, "D MMMM YYYY")
                : "Не указана"
            }
            style={{ fontSize: "14px" }}
          />
        }
      />
      <RowLayout
        leftSpan={16}
        leftComponent={
          <Controller
            name="dismissReason"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormSelect
                label={"Причина увольнения"}
                options={OPTION_TERMINATION_REASON}
                disabled={true}
                {...field}
              />
            )}
          />
        }
      />
      <RowLayout
        leftComponent={
          <Controller
            name="lastShiftRate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormInput
                type="text"
                label={"Тариф по последней смене в день"}
                suffix="₽"
                value={value ?? ""}
                error={error?.message}
                disabled={true}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(value) || value === "") {
                    onChange(value);
                  }
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (value) {
                    onChange(parseFloat(value).toFixed(2));
                  }
                }}
              />
            )}
          />
        }
        centerComponent={
          <FormText
            label={"Общий стаж работника"}
            text={formatDurationMonths(candidate.experienceFull ?? 0)}
            style={{ fontSize: "14px" }}
          />
        }
        rightComponent={
          <FormText
            label={"Стаж в компании"}
            text={formatDurationMonths(candidate.experienceInCompany ?? 0)}
            style={{ fontSize: "14px" }}
          />
        }
      />
      <RowLayout
        leftSpan={8}
        leftComponent={
          <Controller
            name="salaryExpectation"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <FormInput
                label={"Зарплатные ожидания (за смену)"}
                required={true}
                error={error?.message}
                disabled={disabledForm}
                suffix="₽"
                {...field}
              />
            )}
          />
        }
        centerSpan={16}
        centerComponent={
          <Controller
            name="positionsNew"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormSelect
                label="Вакансии которые рассматривает кандидат"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                maxTagCount="responsive"
                filterOption={filterOption}
                disabled={disabledForm}
                options={positionsDirectory?.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                maxTagPlaceholder={(omittedValues) => {
                  const omittedLabels = omittedValues
                    .map((v) => v.label)
                    .join(", ");
                  return (
                    <Tooltip title={omittedLabels}>
                      Ещё {omittedValues.length}
                    </Tooltip>
                  );
                }}
                {...field}
              />
            )}
          />
        }
      />
    </div>
  );
};

export default CandidatePosition;
