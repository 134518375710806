import React, { useCallback, useRef, useState } from "react";
import cn from "classnames/dedupe";
import { DaDataAddressSuggestion } from "react-dadata";

import { useAppDispatch } from "services/store/store";
import {
  CandidateType,
  MutationUpdateCandidateArgs,
} from "graphql/types/types";
import { useHover } from "common/hooks/useHover";
import { useToggle } from "common/hooks/useToggle";
import Input from "components/UI/Input";
import { Edit, Close, CornerUpRight } from "components/UI/icons";
import { updateCandidateInList } from "services/store/modules/candidates";
import { updateCandidate } from "services/store/modules/candidates/action";
import { SelectDadata } from "components/UI/SelectDadata";
import {
  transformToAddressInputType,
  transformToDaDataAddressSuggestion,
} from "common/helpers/transformDaData";
import { useDebounceCallback } from "common/hooks/useDebounceCallback";
import { getUpdatedCandidatePayload } from "../../helpers";
import { IconStatus, Status } from "components/UI/iconStatus";
import { CandidatesTableMode } from "components/CandidatesTable";
import { updateCandidateInReply } from "services/store/modules/repliesList";
import { updateCandidateInClaim } from "services/store/modules/claimsList";

import "./styles.scss";

type Props = {
  name?: string;
  type: "secondName" | "firstName" | "patronymic" | "actualAddress";
  address?: CandidateType["actualAddress"];
  candidate: CandidateType;
  classNames?: string;
  mode?: CandidatesTableMode;
  disable?: boolean;
};

export const FieldName: React.FC<Props> = ({
  name,
  type,
  address,
  candidate,
  classNames,
  mode,
  disable,
}) => {
  const dispatch = useAppDispatch();

  const secondNameRef = useRef<HTMLDivElement>(null);

  const isHover = useHover(secondNameRef);
  const [isEdit, toggleIsEdit] = useToggle(false);

  const [value, setValue] = useState(name ?? "");
  const [suggestion, setSuggestion] = useState<
    DaDataAddressSuggestion | undefined
  >(transformToDaDataAddressSuggestion(address));
  const [status, setStatus] = useState<Status | null>(null);

  const debounceStatus = useDebounceCallback(setStatus, 3000);

  const handleChangeEditClick = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    toggleIsEdit();
  };

  const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }

    updateCandidateForm();
  };

  const updateCandidateForm = useCallback(async () => {
    setStatus("pending");

    const payload: MutationUpdateCandidateArgs = {
      form: {
        ...getUpdatedCandidatePayload(candidate),
        [type]:
          type === "actualAddress"
            ? transformToAddressInputType(suggestion)
            : value,
      },
      id: candidate.id,
    };

    try {
      const result = await dispatch(updateCandidate(payload)).unwrap();

      if (!result?.updateCandidate) {
        setStatus("error");
        return;
      }

      dispatch(updateCandidateInList(result.updateCandidate));
      dispatch(updateCandidateInReply(result.updateCandidate));
      dispatch(updateCandidateInClaim(result.updateCandidate));
      setStatus("success");
    } catch {
      setStatus("error");
    } finally {
      toggleIsEdit();
      debounceStatus(null);
    }
  }, [
    candidate,
    debounceStatus,
    dispatch,
    suggestion,
    toggleIsEdit,
    type,
    value,
  ]);

  const saveIcon = (
    <span
      className={cn("field-name-icon", {
        "field-name-icon--address": type === "actualAddress",
      })}
      onClick={(e) => {
        e.stopPropagation();
        updateCandidateForm();
      }}
    >
      <CornerUpRight />
    </span>
  );

  const inputName = isEdit ? (
    <Input
      size="middle"
      value={value}
      suffix={saveIcon}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleEnterClick}
      onClick={(e) => e.stopPropagation()}
    />
  ) : (
    name
  );

  const inputAddress = isEdit ? (
    <div className="field-name-address-suggest">
      <SelectDadata
        value={suggestion}
        onChange={(suggestion) => setSuggestion(suggestion)}
        inputProps={{
          className: "field-name-address",
          onKeyDown: handleEnterClick,
          onClick: (e) => e.stopPropagation(),
        }}
        suggestionsClassName={cn({
          "field-name-address-suggest-suggestions global-scrollbar":
            mode === "reply",
        })}
      />
      {saveIcon}
    </div>
  ) : (
    <div className="field-name-short">{address?.value ?? ""}</div>
  );

  return (
    <div
      className={cn("field-name", classNames, {
        "field-name-edit": isEdit,
        "field-name-hover": isHover,
      })}
      ref={disable ? undefined : secondNameRef}
    >
      {type === "actualAddress" ? inputAddress : inputName}

      {(isHover || isEdit) && !status && (
        <span className="field-name-icon" onClick={handleChangeEditClick}>
          {isEdit ? (
            <Close width={16} height={16} />
          ) : (
            <Edit width={16} height={16} />
          )}
        </span>
      )}
      {status && (
        <span className="field-name-icon">
          <IconStatus status={status} />
        </span>
      )}
    </div>
  );
};
