import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export const Mail: React.FC<Props> = ({
  width = 18,
  height = 16,
  color = "#0057AC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99984 0.5H13.9998C15.8408 0.5 17.3332 1.99238 17.3332 3.83333V12.1667C17.3332 14.0076 15.8408 15.5 13.9998 15.5H7.74984C7.2896 15.5 6.9165 15.1269 6.9165 14.6667V11.75C6.9165 9.90905 5.42412 8.41667 3.58317 8.41667H1.49984C1.0396 8.41667 0.666504 8.04357 0.666504 7.58333V3.83333C0.666504 1.99238 2.15889 0.5 3.99984 0.5ZM4.34653 4.1466C4.05932 3.95513 3.67128 4.03274 3.47981 4.31994C3.28834 4.60715 3.36595 4.99519 3.65315 5.18666L6.80415 7.28733C8.13375 8.17373 9.86593 8.17373 11.1955 7.28733L14.3465 5.18666C14.6337 4.99519 14.7113 4.60715 14.5199 4.31994C14.3284 4.03274 13.9404 3.95513 13.6532 4.1466L10.5022 6.24727C9.59243 6.85375 8.40726 6.85375 7.49753 6.24727L4.34653 4.1466ZM0.0415039 10.5C0.0415039 10.1548 0.321326 9.875 0.666504 9.875H4.83317C5.17835 9.875 5.45817 10.1548 5.45817 10.5C5.45817 10.8452 5.17835 11.125 4.83317 11.125H0.666504C0.321326 11.125 0.0415039 10.8452 0.0415039 10.5ZM0.666504 12.375C0.321326 12.375 0.0415039 12.6548 0.0415039 13C0.0415039 13.3452 0.321326 13.625 0.666504 13.625H4.83317C5.17835 13.625 5.45817 13.3452 5.45817 13C5.45817 12.6548 5.17835 12.375 4.83317 12.375H0.666504Z"
        fill={color}
      />
    </svg>
  );
};
