import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";

const unwantedStatusKeys = [
  "COMPLETED_CANDIDATE",
  "SIGNUP_SHIFT",
  "WORKED_SHIFT",
  "CANDIDATE_DISMISSAL",
  "FIRED",
  "NEW",
  "EXPIRED",
];
export const selectCandidate = (state: RootState) => state.candidates.candidate;
export const selectCandidateData = createSelector(
  [selectCandidate],
  (candidate) => candidate.candidate
);
export const selectCandidateLoading = createSelector(
  [selectCandidate],
  (candidate) => candidate.loading
);
export const selectCandidateError = createSelector(
  [selectCandidate],
  (candidate) => candidate.error
);
export const selectCandidateList = (state: RootState) =>
  state.candidates.candidatesList;
export const selectDictionaries = (state: RootState) =>
  state.candidates.candidatesDictionaries;

export const selectCandidateHistory = (state: RootState) =>
  state.candidates.candidateHistory.data;

export const selectReplies = (state: RootState) =>
  state.candidates.candidatesReply;

export const selectMainReply = createSelector(
  [selectReplies],
  (replies) => replies?.data?.data?.find((reply) => reply.isMain) || null
);

export const selectCandidateStatuses = createSelector(
  selectDictionaries,
  (state) => state.data?.dictionaries.candidateStatuses ?? []
);
export const selectCandidateStatusesRabotut = createSelector(
  [selectCandidateStatuses],
  (statuses) => {
    return statuses?.filter(
      (status) => !unwantedStatusKeys.includes(status.key)
    );
  }
);

export const selectCandidateMainPhone = createSelector(
  selectCandidate,
  ({ candidate }) =>
    Array.isArray(candidate.contacts)
      ? candidate.contacts.find((contact) => contact.isMain) ??
        candidate.contacts[0]
      : null
);

export const selectCandidateStatusesByRole = (role: string) =>
  createSelector([selectCandidateStatusesRabotut], (statuses) => {
    if (role === "EMPLOYEE_CC") {
      return statuses?.filter((status) => status.key !== "CANDIDATE");
    } else if (role === "MANAGER") {
      return statuses?.filter(
        (status) => status.key !== "TRANSFERRED_TO_HR_FROM_CC"
      );
    } else {
      return statuses;
    }
  });

export const selectCitizenships = createSelector(
  [selectDictionaries],
  (dictionaries) => dictionaries?.data?.dictionaries?.citizenships
);
export const selectSources = createSelector(
  [selectDictionaries],
  (dictionaries) => dictionaries?.data?.dictionaries?.sources
);
export const selectPositions = createSelector(
  selectDictionaries,
  (dictionaries) => dictionaries?.data?.dictionaries?.positions || []
);

export const selectPositionNameById = createSelector(
  [selectPositions],
  (positions) => (id: number) => {
    const position = positions?.find((pos) => pos.id === id);
    return position ? position.name : "";
  }
);
export const selectPositionNameByKey = createSelector(
  [selectPositions],
  (positions) => (key: string) => {
    const position = positions?.find((pos) => pos.key === key);
    return position ? position.name : "";
  }
);

export const selectSourcesNameByKey = createSelector(
  [selectSources],
  (selectSources) => (key: string) => {
    const source = selectSources?.find((el) => el.key === key);
    return source ? source.name : "";
  }
);
export const selectStatusesNameByKey = createSelector(
  [selectCandidateStatuses],
  (candidateStatuses) => (key: string) => {
    const status = candidateStatuses?.find((el) => el.key === key);
    return status ? status.name : "";
  }
);
