import React from "react";
import toaster from "components/UI/Notifications/Notification";
import { PhoneCall } from "components/UI/icons";
import { Button } from "components/UI/Button";
import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  selectIsActiveCall,
  selectOktellStatus,
} from "services/store/modules/sockets/selectors";
import { setCurrentCallCandidate } from "services/store/modules/calls";
import { callOktell } from "services/store/modules/sockets/actions";
import { setupCallCandidate } from "services/store/modules/candidates/action";
import { CandidateType, ContactType } from "graphql/types/types";

import "./styles.scss";

interface OktellButtonProps {
  candidate: CandidateType;
  vacancyId: number;
  contact: ContactType["contact"];
}

export const OktellButton: React.FC<OktellButtonProps> = ({
  candidate,
  vacancyId,
  contact,
}) => {
  const dispatch = useAppDispatch();
  const isOktellAccess = useAppSelector(selectOktellStatus);
  const isActiveCall = useAppSelector(selectIsActiveCall);
  const canCall = isOktellAccess !== "Оффлайн";

  const onCallOktell = async () => {
    if (isActiveCall) {
      toaster.warning({ title: "the call is already in progress" });
      return;
    }

    if (contact) {
      await dispatch(setupCallCandidate({ id: candidate.id, vacancyId }));
      dispatch(setCurrentCallCandidate(candidate));
      dispatch(callOktell(contact));
    }
  };

  const handleClickOktell = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!canCall) {
      return;
    }

    onCallOktell();
  };
  return (
    <Button
      className="oktell-btn"
      onClick={handleClickOktell}
      icon={<PhoneCall />}
    >
      Позвонить
    </Button>
  );
};
