import React, { useState } from "react";
import { useHistory } from "react-router";
import cn from "classnames";

import { Card } from "components/UI/Card";
import { ViewMode, ViewModeToggle } from "components/ViewModeToggle";
import { ROUTE } from "routes";
import { ReportName } from "common/const/reports";
import { getReportCards } from "./helpers";

import "./styles.scss";

export const Reports: React.FC = () => {
  const history = useHistory();

  const [mode, setMode] = useState<ViewMode>("grid");

  const handleClick = (reportName: ReportName) => {
    history.push(`${ROUTE.REPORT}/${reportName}`);
  };

  const handleChangeMode = (mode: ViewMode) => setMode(mode);

  const reportCards = getReportCards(handleClick);

  return (
    <div className="reports">
      <div className="reports-header">
        <ViewModeToggle mode={mode} onChangeMode={handleChangeMode} />
      </div>

      <div className={cn("reports-cards", `reports-cards--${mode}`)}>
        {reportCards.map(({ title, icon, isVisible, onClick }) => (
          <>
            {isVisible ? (
              <Card
                className={cn("reports-card", `reports-card--${mode}`)}
                mode={mode}
                title={title}
                icon={icon}
                onClick={onClick}
              />
            ) : null}
          </>
        ))}
      </div>
    </div>
  );
};
