import React, { useMemo } from "react";
import cn from "classnames/dedupe";

import { CandidateType, ContactType } from "graphql/types/types";
import { Button } from "components/UI/Button";
import { PhoneCall } from "components/UI/icons";
import { useAppSelector } from "services/store/store";
import { companySettings } from "services/store/modules/company";
import { isCallTimeValid } from "common/helpers/getDateTimezone";
import { Info } from "components/UI/icons/Info";
import { Tooltip } from "components/UI/Tooltip";
import { useToggle } from "common/hooks/useToggle";
import { DropdownCallsMenu } from "components/Calls/DropdownCallsMenu";

import "./styles.scss";

interface Props {
  candidate: CandidateType;
  vacancyId: number;
  contact: ContactType["contact"];
  className?: string;
}
export const CallButton: React.FC<Props> = ({
  contact,
  candidate,
  vacancyId,
  className,
}) => {
  const company = useAppSelector(companySettings);

  const [isDropdownVisible, toggleDropdownVisible, setDropdownVisible] =
    useToggle(false);
  const timezone = candidate?.timezone ?? "";
  const callTimeStart = company?.callTimeStart ?? "";
  const callTimeEnd = company?.callTimeEnd ?? "";
  const isCallButtonEnabled = useMemo(() => {
    if (!timezone) {
      return true;
    }
    return isCallTimeValid(timezone, callTimeStart, callTimeEnd);
  }, [timezone, callTimeStart, callTimeEnd]);

  return (
    <div className="call-button-container">
      <Button
        icon={
          isCallButtonEnabled ? (
            <PhoneCall />
          ) : (
            <Tooltip
              placement="bottomRight"
              title={`Звонок кандидату доступен с ${callTimeStart} до ${callTimeEnd} по его часовому поясу`}
            >
              <span className="tooltip-icon-wrapper">
                <Info color="#0057AC" height={20} width={20} />
              </span>
            </Tooltip>
          )
        }
        className={cn(className)}
        onClick={toggleDropdownVisible}
        disabled={!isCallButtonEnabled}
      >
        Позвонить
      </Button>
      {contact && (
        <DropdownCallsMenu
          vacancyId={vacancyId}
          candidate={candidate}
          contact={contact}
          isVisible={isDropdownVisible}
          setDropdownVisible={setDropdownVisible}
        />
      )}
    </div>
  );
};
